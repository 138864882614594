class Preloader {
    init() {
        $('[data-role="preloader"]').addClass('init');
    }

    close() {
        $('[data-role="preloader"]').addClass('loaded');
        setTimeout(() => {
            $('[data-role="preloader"]').css({
                'opacity': 0
            });
            setTimeout(() => {
                $('[data-role="preloader"]').css({
                    'display': 'none'
                });
            }, 200);
        }, 1000);
    }
}

export default new Preloader();