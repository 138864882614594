import DynamicBackground from '../../js-utils/DynamicBackground';

/**
 * Main app component
 * Utilities and usefull functions
 */
const App = (function() {

    const SMALL_DEVICE = 1025; // Less than bootstrap LG breakpoint

    /**
     * 
     * Public methods goes here
     * 
     * */
    return {

        // Detects if device is touch
        is_touch: /(iphone|ipod|ipad|android|iemobile|blackberry|bada)/.test(window.navigator.userAgent.toLowerCase()),

        // Detects browser is IE11
        is_ie11: !!window.MSInputMethodContext && !!document.documentMode,

        // Detects if device is MAC
        is_mac: navigator.platform.toLowerCase().indexOf('mac') >= 0,

        // Detects if device is Andoroid
        is_android: /(android)/.test(window.navigator.userAgent.toLowerCase()),

        // Detects if device is small - Less than bootstrap LG breakpoint
        is_small_device: $(window).innerWidth() < SMALL_DEVICE,

        is_portret_orientation: function() {

            let wWidth = $(window).innerWidth(),
                wHeight = $(window).innerHeight(),
                ratio = wWidth / wHeight;

            return ratio <= 1;
        },

        /**
         * Detects if device is mobile
         * @returns {Boolean}
         */
        is_mobile: function() {
            return this.is_small_device && (this.is_touch || this.is_android);
        },

        // Метод инициализирует 
        initFullPage: function() {

            if (true === App.is_small_device) return;

            $('#app').fullpage({
                keyboardScrolling: true,
                anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8', 'page9', 'page10'],
                navigation: true,
                navigationPosition: 'right',
                navigationTooltips: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'],
            });
        },

        initDynamicBackground: function() {
            let dBackground = new DynamicBackground();

            dBackground.initModule({
                sectionSelector: '[data-dynamic-background]',
                elemSelector: '.js-app-dynamic-background'
            });
        },

        slicePrice: function(price) {
            return parseInt(price).toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, "\$1 ");
        }
    };

})();

export default App;