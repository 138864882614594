import App from '../../app/xs/app';

class Faq {

    constructor() {
        this.DOMMap = {
            items: $('[data-role="faq-item"]'),
            modal: $('[data-role="faq-modal"]'),
            modalTitle: $('[data-role="faq-modal-title"]'),
            modalContent: $('[data-role="faq-modal-content"]')
        };
    }

    init() {

        this.initHandlers();

    }

    initHandlers() {

        let self = this;

        this.DOMMap.items.on('click', function() {
            if (App.is_small_device) {
                self.expandItem(this);
            } else {
                self.showAsideItem(this);
            }
        });
    }

    /**
     * @param {HTML Element} itemElem
     */
    expandItem(itemElem) {
        let item = $(itemElem),
            content = item.find('[data-role="faq-item-content"]');

        content.slideToggle();
        item.toggleClass('active');
    }

    /**
     * 
     * @param {HTML Element} itemElem
     */
    showAsideItem(itemElem) {
        let item = $(itemElem),
            title = item.find('[data-role="faq-item-title"]').text(),
            text = item.find('[data-role="faq-item-content"]').text();

        this.DOMMap.items.removeClass('active');
        item.addClass('active');

        this.DOMMap.modalTitle.text(title);
        this.DOMMap.modalContent.text(text);

    }
}

export default new Faq();