import Calc from '../../calc/xs/calc';
import App from '../../app/xs/app';
import axios from 'axios';

const DOMMap = {
    forms: null,
    activeFields: null,
    floatFormTrigger: null,
    nextStepButton: null
};

const allowedExtensions = ['txt', 'doc', 'docx', 'rtf', 'pdf', 'jpg', 'jpeg', 'png', 'xls', 'xlsx'];
const allowedTypes = [
    'text/plain',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/rtf',
    'image/png',
    'image/jpeg'
];

let tempFiles; // TEMPORARY storage form uploaded files

function validateFile(fileObj) {

    let ext = fileObj.name.split('.').pop(),
        extIsValid = allowedExtensions.indexOf(ext) != -1,
        mimeTypeIsValid = allowedTypes.indexOf(fileObj.type) != -1,
        fileIsValid = extIsValid && mimeTypeIsValid;

    return fileIsValid;
}

function noopE(e) { e.preventDefault(); }

function handleFiles(files, area) {

    const $area = $(area),
        realFileInput = $area.prev()[0],
        areaFileListContainer = $area.parent().find('.form__file-list');

    tempFiles = files;

    let filesCount = 0;

    for (let i in files) {
        let file = files[i];

        if (file.size && validateFile(file)) {
            filesCount++;
        } else {
            delete files[i];
        }
    }

    if (filesCount > 0) {
        areaFileListContainer.html(`файлов: ${filesCount}`);
    } else {
        areaFileListContainer.html('');
    }
}

function validatePhone(phone) {
    return /^((8|\+7|7)[\- ]?)?(\(?\d{2,3}\)?[\- ]?)?[\d\- ]{5,10}$/gm.test(phone);
}

class ActiveForm {

    init() {

        // Инициализируем range компоненты
        let calcItems = $('[data-role="form-calc"]');

        calcItems.each((index, item) => {
            let calc = new Calc({
                form: $(item)
            });

            calc.init();
        });

        DOMMap.forms = $('[data-active-form]');
        DOMMap.activeFields = $('[data-role="form-active-field"]');
        DOMMap.activeInputs = DOMMap.activeFields.find('input');
        DOMMap.floatFormTrigger = $('[data-role="float-form-trigger"]');
        DOMMap.formBaseTrigger = $('[data-role="form-base-trigger"]');
        DOMMap.nextStepButton = $('[data-role="conditions-form-next"]');

        this.initHandlers();
        this.initActiveFields();
        this.initPhoneMasks();
        this.initFilePickers();

    }

    initHandlers() {

        const self = this;

        DOMMap.forms.on('submit', function(e) {

            e.preventDefault();

            const form = this,
                $form = $(form),
                phone = $form.find('input[name="phone"]');

            if (form.hasAttribute('data-multistep') &&
                $form.find('[data-step="1"]').hasClass('active') &&
                validatePhone(phone.val())
            ) {
                self.showNextStep($form);
                return false;
            }

            self.handleSubmit(form);
        });

        DOMMap.floatFormTrigger.on('click', function() {
            self.showFloatForm($(this));
        });

        DOMMap.formBaseTrigger.on('click', function() {
            $.fancybox.open({
                src: `#form-base`,
                touch: false
            });
        });

        DOMMap.nextStepButton.on('click', function() {
            let form = $(this).parents('[data-active-form]');
            self.showNextStep(form);
        });

    }

    handleSubmit(form) {

        let propertyIsValid = this.validateProperty(form);

        if (propertyIsValid) {
            this.sendFullData(form);
        }

    }

    /**
     * Валидирует - выбрана ли форма собственности под залог в форме
     * @param {HTML Element} form 
     */
    validateProperty(form) {
        let $form = $(form),
            propertyRadios = $form.find('input[name="property"]'),
            radiosContainer = $form.find('.conditions__form-radio'),
            valid = false;

        if (propertyRadios.length <= 0) return true; // Если radio с формами собственности нет вообще

        propertyRadios.each((index, item) => {
            if (item.checked) valid = true;
        });

        if (false === valid) {
            radiosContainer.addClass('error');
            setTimeout(() => {
                radiosContainer.removeClass('error');
            }, 300);
        } else {
            radiosContainer.removeClass('error');
        }

        return valid;
    }

    initActiveFields() {

        const self = this;

        DOMMap.activeInputs.on('focus', function() {
            let elems = self._getActiveFieldElements(this);
            elems.label.addClass('shifted');
        });

        DOMMap.activeInputs.on('focusout', function() {
            let elems = self._getActiveFieldElements(this),
                value = this.value.trim();

            if (value === '') {
                elems.label.removeClass('shifted');
            }

        });

        // Активация активных инпутов, если в них уже есть значение при загрузке страницы
        DOMMap.activeInputs.each(function(index, item) {
            let elems = self._getActiveFieldElements(this),
                value = this.value.trim();

            if (value !== '') {
                elems.label.addClass('shifted');
            }
        });

        $('[data-phone-mask]').on('input', function() {
            let phone = this.value,
                phoneIsValid = validatePhone(phone),
                form = $(this).parents('[data-active-form]'),
                nextBtn = form.find('[data-role="conditions-form-next"]');

            if (true === phoneIsValid) {
                nextBtn.removeClass('inactive');
                $(this).removeClass('error');
            } else {
                nextBtn.addClass('inactive');
            }


        });
    }

    initPhoneMasks() {
        if ($('[data-phone-mask]').length > 0) {

            let inputs = document.querySelectorAll('[data-phone-mask]');

            for (var i = 0; i < inputs.length; i++) {
                let mask = new Inputmask({
                    mask: "+7 (999) 999-99-99",
                    showMaskOnHover: false
                });
                let phoneInput = inputs[i];
                mask.mask(phoneInput);
            }
        }
    }

    initFilePickers() {
        let dropAreas = document.querySelectorAll('[data-role="form-file"]');

        for (let i = 0; i < dropAreas.length; i++) {
            this.initDropArea(dropAreas[i]);
        }
    }

    initDropArea(area) {

        function highlight() {
            area.classList.add('highlight');
        }

        function unhighlight(e) {
            area.classList.remove('highlight')
        }

        let input = $(area).parent().find('input')[0];

        input.addEventListener('change', this._handleFileChange, false);

        if (false === App.is_small_device) {
            // Если не отменить стандартное поведение, то браузер просто прочитает файл при перетаскивании
            area.addEventListener('dragover', noopE, false);
            area.addEventListener('dragenter', noopE, false);
            area.addEventListener('dragleave', noopE, false);


            area.addEventListener('drop', this._handleFileDrop, false);

            ;
            ['dragenter', 'dragover'].forEach(eventName => {
                area.addEventListener(eventName, highlight, false)
            })

            ;
            ['dragleave', 'drop'].forEach(eventName => {
                area.addEventListener(eventName, unhighlight, false)
            })
        }
    }

    showNextStep(form) {

        let firstStep = form.find('[data-step="1"]'),
            secondStep = form.find('[data-step="2"]'),
            title = form.parent().find('.conditions__form-title'),
            formNextStepButton = form.find('[data-role="conditions-form-next"]'),
            formSubmit = form.find('.conditions__form-button_order'),
            formShiftContainer = form.parents('[data-role="form-shift"]'),
            formShiftContainerWrapper = formShiftContainer.parent(),
            formShiftContainerWrapperHeight = formShiftContainerWrapper.height();


        if (formShiftContainer.length > 0 && false === App.is_small_device) {
            formShiftContainerWrapper.css({
                'height': `${formShiftContainerWrapperHeight}px`
            });
            formShiftContainer.css({
                'transform': 'translateY(-30px)'
            });
        }

        firstStep.css({
            'transform': `translateX(-110%)`
        });

        firstStep.removeClass('active');

        secondStep.removeClass('inactive');
        secondStep.addClass('active');

        title.text('Пара моментов и деньги ваши');

        this.sendFirstStep(form[0]);

        formNextStepButton.addClass('passed');
        formSubmit.removeClass('disabled');

    }

    async sendFirstStep(form) {

        let data = new FormData(form);
        let response = await this.sendData(data);

        if (response.data === 'ok') {
            this.sendGoal('order-landing-kirov-1');
        }


    }

    async sendFullData(form) {

        let $form = $(form),
            formData = new FormData();

        // Собираем данные вручную, так как нужно провалидировать файлы перед отправкой

        let phone = $form.find('input[name="phone"]').val(),
            name = $form.find('input[name="name"]').val(),
            summ = $form.find('input[name="summ"]').val(),
            period = $form.find('input[name="period"]').val(),
            property = $form.find('input[name="property"]').val();

        if (false === validatePhone(phone)) {
            $form.find('input[name="phone"]').addClass('error');
            return false;
        } else {
            $form.find('input[name="phone"]').removeClass('error');
        }

        formData.append('phone', phone);
        formData.append('name', name);

        // Есть форма без этих полей
        if (summ) {
            formData.append('summ', summ);
        }
        if (period) {
            formData.append('period', period);
        }
        if (property) {
            formData.append('property', property);
        }

        let fileInput = $form.find('.form__file-input'),
            files;

        this.disableForm($form);

        if (fileInput.length > 0) {

            files = tempFiles;

            for (let i in files) {
                let file = files[i],
                    name = `file${i}`;
                if (file.size && validateFile(file)) {
                    formData.append(name, file);
                }
            }
        }

        let response;

        try {
            response = await this.sendData(formData);
        } catch (e) {
            console.error(e);
            this.showError($form);
            return;
        }

        if (typeof response !== 'undefined' && response.data === 'ok') {
            this.showSuccess($form);
            if ($form.data('role') == 'form-calc') {
                this.sendGoal('order-landing-kirov-2');
            } else {
                this.sendGoal('order-landing-kirov-1');                
            }
        } else {
            this.showError($form);
        }

        tempFiles = null;

    }

    async sendData(data) {
        return axios.post('send.php', data);
    }

    /**
     * Перекрыть форму перед отправкой заявки
     * @param {Jquery object} form 
     */
    disableForm(form) {
        let loader = form.find('[data-role="form-loader"]');
        loader.addClass('loading');
    }

    /**
     * Делает форму снова доступной для заявки
     * @param {Jquery object} form 
     */
    enableForm(form) {
        let loader = form.find('[data-role="form-loader"]');
        loader.removeClass('loaded');
    }

    /**
     * Показать сообщение об успешной отправке заявки
     * @param {Jquery object} form 
     */
    showSuccess(form) {

        let formShiftContainer = form.parents('[data-role="form-shift"]'),
            formShiftContainerWrapper = formShiftContainer.parent();

        setTimeout(() => {
            let loader = form.find('[data-role="form-loader"]');
            loader.removeClass('loading');

            loader.addClass('loaded');

            setTimeout(() => {
                this.resetForm(form);

                if (formShiftContainer.length > 0 && false === App.is_small_device) {
                    formShiftContainer.css({
                        'transform': 'translateY(0)'
                    });
                    formShiftContainerWrapper.css({
                        'height': 'auto'
                    });
                }

            }, 1000);

        }, 500);
    }

    /**
     * 
     * @param {Jquery Object}  
     */
    resetForm(form) {
        $.fancybox.close();
        $.fancybox.close();
        form.find('[data-step="1"]').addClass('active');
        form.find('[data-step="1"]').attr('style', '');
        form.find('[data-step="2"]').removeClass('active');
        form.find('[data-step="2"]').addClass('inactive');
        form.find('[data-role="conditions-form-next"]').removeClass('passed');
        form.find('[data-role="conditions-form-next"]').addClass('inactive');
        if (form[0].hasAttribute('data-multistep')) {
            form.find('.conditions__form-button_order').addClass('disabled');
        }
        form.find('input[name="phone"]').val('');
        setTimeout(() => { this.enableForm(form); }, 1000);
    }

    /**
     * Показать сообщение об ошибке при отправке заявки
     * @param {Jquery object} form 
     */
    showError(form) {
        let loader = form.find('[data-role="form-loader"]');
        loader.removeClass('loading');

        alert('При отправке заявки произошла ошибка. Попробуйте позднее. Администрация оповещена.');

        // do not have enougth time to handle error properly
    }

    /**
     * Обработчик события на добавление файлов через нажатие
     * @param {object} e 
     */
    _handleFileChange(e) {
        e.preventDefault();

        let files = this.files,
            area = $(this).next();

        handleFiles(files, area);
    }

    /**
     * Обработчик события на добавление через перетаскивание
     * @param {object} e 
     */
    _handleFileDrop(e) {

        e.preventDefault();

        let dt = e.dataTransfer;
        let files = dt.files;

        handleFiles(files, $(this));
    }



    showFloatForm(btn) {

        if (btn.data('get-values')) {
            let wrap = btn.parents('[data-active-form]'),
                money = wrap.find('input[name="money"]').val(),
                period = wrap.find('input[name="period"]').val();

            let floatFormMoney = $('#form-float').find('input[name="summ"]'),
                floatFormPeriod = $('#form-float').find('input[name="period"]');

            floatFormMoney.val(money);
            floatFormPeriod.val(period);
        }

        $.fancybox.open({
            src: `#form-float`,
            touch: false
        });
    }

    _getActiveFieldElements(fieldInput) {

        let input = $(fieldInput),
            parentField = input.parent(),
            label = parentField.find('label');

        return {
            input,
            parentField,
            label
        };
    }

    sendGoal(goalId) {
        if (typeof yaCounter48508145 != 'undefined') {
            yaCounter48508145.reachGoal(goalId);
        }

        if (typeof gtag !== 'undefined') {
            gtag('event', goalId, {
                'event_category': 'Landing order'
            });
        }
    }

}

export default new ActiveForm();