class DocumentsList {

    constructor() {
        this.DOMMap = {};
    }

    init() {
        if ($('[data-role="documents-list"]').length <= 0) return false;

        this.DOMMap.buttons = $('[data-role="documents-list-button"]');
        this.DOMMap.rosters = $('[data-role="documents-list-roster"]');

        this.initHandlers();

    }

    initHandlers() {
        this.DOMMap.buttons.each((index, item) => {
            $(item).on('click', () => {
                this.highlightButtons(index);
                this.showRoster(index);
            });
        });
    }

    /**
     * Highlights controls button by given index
     * @param {number} index 
     */
    highlightButtons(index) {
        this.DOMMap.buttons.removeClass('active');
        this.DOMMap.buttons.eq(index).addClass('active');
    }

    /**
     * Shows roster by given index
     * @param {number} index 
     */
    showRoster(index) {
        this.DOMMap.rosters.removeClass('active');
        this.DOMMap.rosters.eq(index).addClass('active');
    }

}

export default new DocumentsList();