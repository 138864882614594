import App from '../app/xs/app';

class DynamicBackground {

    constructor() {
        this.DOMMap = {
            sections: [],

        };

        this.elemSelector = null;
    }

    initModule(config) {

        this.elemSelector = config.elemSelector;

        let sections = $(config.sectionSelector);

        sections.each((index, item) => {
            let $item = $(item),
                dependElem = $item.find('.app__slide-part_big'),
                backgroundElem = $item.find(this.elemSelector);

            this.DOMMap.sections.push({
                section: $item,
                dependElem,
                backgroundElem
            });
        });

        this.resizeElements();
        this.listen();
        this.showElements();
    }

    resizeElements() {

        Array.prototype.slice.call(this.DOMMap.sections).forEach((section, index) => {

            let dependElemWidth = section.dependElem.width(),
                dependElemOffset = section.dependElem.offset().left,
                backgroundWidth = dependElemWidth + dependElemOffset;

            section.backgroundElem.css({
                'width': `${backgroundWidth}px`
            });
        });

    }

    listen() {
        $(window).on('resize', () => {
            this.resizeElements();
        });
    }

    showElements() {
        this.DOMMap.sections.forEach((section, index) => {
            section.backgroundElem.addClass('active');
        });
    }

}

export default DynamicBackground;