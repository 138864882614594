import App from '../../app/xs/app';

function CreateRangeComponent(config) {

    const mainElem = config.container;

    if (mainElem.length <= 0) throw new Error('RANGE: No container element');

    const input = mainElem.find('[data-role="range-value"]');

    this.element = mainElem;
    this.minRange = parseInt(mainElem.attr('data-minrange'));
    this.maxRange = parseInt(mainElem.attr('data-maxrange'));
    this.val = parseInt(input.val());
    this.unit = mainElem.attr('data-unit');
    this.type = config.type;

    this.line = mainElem.find('[data-role="range-line"]');
    this.step = mainElem.attr('data-step') ? parseInt(mainElem.attr('data-step')) : 1;

    this.input = input;

    this.lastValue = 0;

    this.noUiSlider = null;

    this.config = config;
}

CreateRangeComponent.prototype.init = function() {

    let self = this;

    this.noUiSlider = noUiSlider.create(this.line[0], {
        start: [0, this.val],
        behaviour: 'none',
        connect: true,
        step: this.step,
        tooltips: false,
        range: {
            'min': this.minRange,
            'max': this.maxRange
        },
        format: {
            to: function(value) {
                let valueStr = '' + Math.floor(value);

                if (
                    valueStr.length > 5 &&
                    valueStr.endsWith('999')
                ) {
                    value += 1;
                }

                return `${self.slicePrice(value)} ${self.formatUnit(value)}`;
            },
            from: function(value) {
                return value;
            }
        }
    });

    this.listenRangeEvents();
}

CreateRangeComponent.prototype.formatUnit = function(value) {

    let result;

    let wMap = {
        'рубль': {
            0: 'рубль',
            1: 'рублей',
            2: 'рубля'
        },
        'месяц': {
            0: 'месяц',
            1: 'месяцев',
            2: 'месяца'
        }
    };

    value = Math.floor(value);

    if ((value === 1 || value % 10 === 1) && value !== 11) {
        result = wMap[this.unit][0];
    } else if ((value >= 12 && value <= 14) || (value % 100 >= 12 && value % 100 <= 14)) {
        result = wMap[this.unit][1];
    } else if (value % 10 >= 2 && value % 10 <= 4) {
        result = wMap[this.unit][2];
    } else {
        result = wMap[this.unit][1];
    }

    return result;

}

CreateRangeComponent.prototype.slicePrice = function(price) {
    return App.slicePrice(price);
}

CreateRangeComponent.prototype.listenRangeEvents = function() {

    const self = this;

    this.noUiSlider.on('update', function(values, handle) {

        const value = values[handle];

        if (handle === 1) {
            self.input.val(value);
            self.value = value;
        }

        self.config.onChange(values, self.type);
    });

    this.listenInputEvents();

}

CreateRangeComponent.prototype.listenInputEvents = function() {

    const self = this;

    let lastValue = this.lastValue;

    this.input.on('focusin', function() {
        lastValue = parseInt(this.value.replace(/\s/g, ''), 10);
    });

    this.input.on('focusout', function() {
        let newValue = parseInt(this.value.replace(/\s/g, ''), 10),
            currentValue = parseInt(self.value.replace(/\s/g, ''), 10);

        if (newValue > this.maxRange) {
            self.noUiSlider.set([0, this.maxRange]);
        } else if (newValue < this.minRange) {
            self.noUiSlider.set([0, this.minRange]);
        } else {
            self.noUiSlider.set([0, newValue]);
        }
    });
}

CreateRangeComponent.prototype.resetToDefault = function() {
    this.setValues([this.minRange, this.maxRange]);
}

CreateRangeComponent.prototype.setValues = function(values) {
    this.noUiSlider.set(values);
}

export default CreateRangeComponent;