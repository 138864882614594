import '@babel/polyfill';

import App from './app/xs/app';
import initMenu from './menu/xs/menu';
import CreateRangeComponent from './range/xs/range';
import initForm from './form/xs/form';
import DocumentList from './list/xs/list';
import Faq from './faq/xs/faq';
import ActiveForm from './form/xs/form';
import Preloader from './preloader/xs/preloader';


Preloader.init();

window.addEventListener('load', function() {
    setTimeout(() => {
        Preloader.close();
    }, 1000);
});


// App entry point

App.initFullPage();
App.initDynamicBackground();

DocumentList.init();
Faq.init();
ActiveForm.init();

initMenu();