import CreateRangeComponent from '../../range/xs/range';
import App from '../../app/xs/app';

class Calc {

    constructor(config) {
        this.form = config.form;

        this.ranges = this.form.find('[data-role="range"]');
        this.result = this.form.find('[data-role="result-container"]');

        this.values = {
            'money': 1000,
            'time': 1
        }

        this.rangesItems = {};
    }

    init() {

        let self = this;

        this.ranges.each((index, item) => {

            let range = new CreateRangeComponent({
                container: $(item),
                type: $(item).data('type'),
                onChange: (values, type) => { self.resultCalculation() }
            });

            range.init();

            this.rangesItems[index] = range;
        });
    }

    resultCalculation() {

        if (this.result.length <= 0) return;

        for (let i in this.rangesItems) {
            let item = this.rangesItems[i],
                values = item.noUiSlider.get(),
                type = item.type;

            let actualValue = parseInt(values[1].replace(/\s/g, ''), 10);

            this.values[type] = actualValue;
        }


        let res = Math.floor((this.values['money'] / 100) * 3);

        this.result.text(App.slicePrice(res) + ' руб');

    }

}

export default Calc;